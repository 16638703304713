.picker-mask {position:fixed;z-index:1000;top:0;left:0;width:100%;height:100%;background-color:rgba(0, 0, 0, .46);}
@-webkit-keyframes pickerShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pickerShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.picker-mask.time-picker-ani {-webkit-animation:pickerShow .2s linear;animation:pickerShow .2s linear;}

.picker-container {position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:280px;opacity:1;font-family:'PingFang SC', 'HanHei SC', 'Helvetica Neue', 'Helvetica', sans-serif;line-height:1.5;background-color:#fff;box-shadow:0 0 10px rgba(0, 0, 0, .8);-webkit-tap-highlight-color:transparent;-webkit-user-select:none;user-select:none;}
.picker-container i {font-style:normal;}
.picker-container [data-active] {position:relative;}
.picker-container [data-active]:after,
.picker-container .picker-disabled.active:after {content:"";position:absolute;top:0;left:0;width:100%;height:100%;background-image:-webkit-radial-gradient(circle, #000000 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #000000 10%, transparent 10.01%);background-image:radial-gradient(circle, #000000 10%, transparent 10.01%);background-repeat:no-repeat;background-position:50%;pointer-events:none;-webkit-background-size:0% 0%;background-size:0% 0%;opacity:0;-webkit-transition:background .5s, opacity 1s;transition: background .5s, opacity 1s;}
.picker-container .active:after {-webkit-background-size:1000% 1000%;background-size:1000% 1000%;opacity:0.2;}
.picker-container .picker-disabled {color:#d6d6d6;}

.picker-head {padding:10px 16px;line-height:1.5;color:#ececec;background-color:#00bcd4;}
.picker-head-active {color:#fff;}
.picker-year {font-size:14px;width:4em;}
.picker-date {font-size:24px;text-indent:-2px;}
.picker-content {position:relative;overflow:hidden;width:100%;height:224px;font-size:12px;color:rgba(0, 0, 0, 0.8);}
.picker-content ul,
.picker-content li {list-style:none;margin:0;padding:0;}

.date-picker-main {width:100%;-webkit-transform:translateX(-100%) translateZ(0);transform:translateX(-100%) translateZ(0);}
.date-picker-main:after {content:"";display:table;clear:both;}

.scroller-picker-main {position:relative;text-align:center;}
.scroller-picker-main li {padding:10px 0;overflow:hidden;}
.scroller-picker-main .picker-active {color:#00bcd4;}
.scroller-picker-midd {position:absolute;top:50%;width:100%;height:40px;margin-top:-20px;-webkit-box-sizing:border-box;box-sizing:border-box;}
.scroller-picker-midd:before,
.scroller-picker-midd:after {content:"";position:absolute;left:0;width:100%;border-top:1px solid #e2e3e5;}
.scroller-picker-midd:before {top:0;-webkit-transform:scaleY(.5) translateZ(0);-webkit-transform-origin:left top;transform-origin:left top;transform:scaleY(.5) translateZ(0);}
.scroller-picker-midd:after {bottom:0;-webkit-transform:scaleY(.5) translateZ(0);-webkit-transform-origin:left bottom;transform-origin:left bottom;transform:scaleY(.5) translateZ(0);}

.picker-actions-arrow {z-index:1;position:absolute;top:0;left:8px;right:8px;font-size:14px;line-height:20px;}
.picker-actions-arrow i {cursor:pointer;float:left;padding:16px 14px;}
.picker-actions-arrow i:last-child {float:right;}
.picker-actions-arrow i[data-active]:after {height:42px;top:50%;-webkit-transform:translate(0, -50%);transform:translate(0, -50%);border-radius:50%;}

.picker-bdy {float:left;width:100%;padding:0 8px;margin-right:-100%;position:relative;-webkit-box-sizing:border-box;box-sizing:border-box;}
.picker-bdy-curr {left:100%;}
.picker-bdy-next {left:200%;}

.date-picker-title {text-align:center;padding:16px;font-size:14px;font-weight:800;line-height:20px;}

.date-picker-days {/*padding-bottom:8px;*/}
.date-picker-days i {position:relative;width:14.28%;height:12px;padding:6px 0;text-align:center;display:inline-block;line-height:1;vertical-align:top;cursor:pointer;overflow:hidden;}
.date-picker-days i[data-active]:after {width:24px;left:50%;-webkit-transform:translate(-50%, 0);transform:translate(-50%, 0);border-radius:50%;}

.date-picker-days-bdy i span {position:relative;}
.date-picker-days-bdy i:before {content:"";left:50%;top:50%;width:0;height:0;position:absolute;border-radius:50%;background-color:#00bcd4;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);}
/*.date-picker-days-bdy i:hover,*/
.picker-bdy-curr .date-picker-days-bdy i.picker-active {color:#fff;}
/*.date-picker-days-bdy i:hover:before,*/
.picker-bdy-curr .date-picker-days-bdy i.picker-active:before {width:24px;height:24px;}
/*.date-picker-days-bdy i:hover:before {transition:all .2s;}*/
.date-picker-days-bdy .picker-now {color:#00bcd4;}

.date-picker-days-title {font-weight:500;}
.date-picker-days-bdy i[data-val="0"] {color:transparent;}
.date-picker-days-bdy i[data-val="0"]:before {content:none;}

.picker-foot {padding:6px 10px;text-align:right;color:#393939;}
.picker-act {padding:5px 15px;margin-left:10px;text-decoration:none;display:inline-block;font-size:14px;font-weight:600;color:inherit;background-color:transparent;border-radius:2px;-webkit-transition:background-color .2s linear;transition:background-color .2s linear;}
.picker-act:hover {background-color:rgba(153, 153, 153, 0.2);}
.picker-act-clear {margin-left:0;float:left;}

.time-picker-container .picker-head {text-align:center;font-size:38px;font-weight:300;}
/*.time-picker-container .picker-content {height:200px;}*/
.time-picker-container .picker-cell {margin-left:-20px;}
.time-picker-container .picker-cell[data-active]:after {width:30px;height:30px;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);border-radius:50%;}
.time-picker-container .picker-cell-inner {margin-left:-12px;}
.time-picker-container .picker-active {z-index:1;color:#fff;pointer-events:none;}
.time-picker-container .picker-active b {position:absolute;left:50%;top:50%;margin-top:-1px;margin-left:-1px;width:2px;height:2px;background-color:#fff;border-radius:50%;}

.time-picker-main {position:absolute;top:50%;left:50%;height:200px;width:200px;-webkit-transform:translate3d(-50%, -50%, 0);transform:translate3d(-50%, -50%, 0);border-radius:50%;background-color:rgba(0, 0, 0, 0.05);}
.time-picker-main > ul {position:absolute;top:1px;right:1px;bottom:1px;left:1px;}
.time-picker-main:before {content:"";position:absolute;top:50%;left:50%;width:8px;height:8px;border-radius:50%;background-color:rgba(0, 188, 212, 0.9);-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);}

.time-picker-main-minutes > .time-picker-minutes-1 {top:10px;right:10px;bottom:10px;left:10px;}
.time-picker-minutes-1 .picker-cell {width:6px;margin-left:-3px;margin-top:-6px;-webkit-transform-origin:50% 96px;transform-origin:50% 96px;}
.time-picker-minutes-1 .picker-cell i {margin-top:-3px;}
.time-picker-minutes-1 .picker-active b {margin-top:-4px;}

.time-picker-minutes-10 .picker-cell {width:80px;margin-left:-40px;}

.picker-hour, .picker-minute {display:inline-block;width:46px;}
.picker-hour[data-active]:after, .picker-minute[data-active]:after {height:46px;top:5px;}
.picker-hour {text-align:right;}
.picker-minute {text-align:left;}
.picker-cell {position:absolute!important;top:0;left:50%;width:40px;height:28px;-webkit-transform-origin:50% 99px;transform-origin:50% 99px;-webkit-transition:all .2s;transition:all .2s;}
.picker-cell i {position:absolute;top:50%;left:50%;pointer-events:none;}
.picker-cell-inner {top:30px;width:24px;height:24px;-webkit-transform-origin:50% 69px;transform-origin:50% 69px;}

.time-picker-line {position:absolute;top:99px;left:100px;width:70px;height:2px;pointer-events:none;background-color:rgba(0, 188, 212, 0.9);-webkit-transform-origin:0 50%;transform-origin:0 50%;-webkit-transition:all .2s;transition:all .2s;}
.time-picker-line:before {content:"";position:absolute;left:100%;top:0;margin-top:-14px;width:30px;height:30px;background-color:rgba(0, 188, 212, 0.9);border-radius:50%;}
.time-picker-line-inner {width:42px;}
